/** @jsx jsx */
//import * as React from "react"
import { jsx, css } from "@emotion/react"
import { SEO } from "../../components/seo"

import Page from "../../layouts/page"
import { Link } from "gatsby"

const NetworkPeeringPolicyPage = () => {
  return <Page>
    <div>
      <div className="contain page-body">
        <ol class="uk-breadcrumb">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/network">Network</Link></li>
          <li><span>Peering Policy</span></li>
        </ol>
        
        <h1>Peering Policy</h1>

        <p>Inferno Communications (AS207841) has an open peering policy.</p>

        <p>We have the following peering preferences:</p>
        
        <ul>
          <li>To peer with a network operating a 24x7 NOC.</li>
          <li>To peer with a network with a PeeringDB page.</li>
          <li>To peer with networks with an up to date routing registry (such as RIPE).</li>
          <li>To peer settlement free.</li>
          <li>Willing to sign a peering contract, but not required.</li>
        </ul>

        <p>If you are interested in peering with us, please <Link to="/contact-us">contact our NOC</Link>.</p>

        <p>We accept private peering requests on a case by case basis.</p>
        <p>Visit our <a href="https://www.peeringdb.com/asn/207841">PeeringDB page</a> for detailed technical information.</p>
      </div>
    </div>
  </Page>
}

export const Head = () => (
  <SEO
    title="Peering Policy"
    path="/network/peering"
  />
)

export default NetworkPeeringPolicyPage
